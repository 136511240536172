<template>
    <div>
        <div class="card bs-4 border-r-0 c-card">
            <div class="row d-sm-none d-md-none pos-r header-section-min-height">
                <div class="line-bottom"></div>

                <div class="col-2">
                    <div class="fl-x fl-j-sb fl-a-s pos-r">
                        <div class="line-right-1"></div>

                        <span class="logo-container">
                        <img class="img-fluid" :src="dataItem.event_bank.bank_logo" alt=""/>
                    </span>

                        <span class="font-inter-medium class=fs-lg--1">{{ dataItem.auction_id }}</span>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center-res font-inter-medium">
                        <div class="line-right-1"></div>
                        <p class="fs-lg--1">{{ dataItem.event_bank.bank_name }}</p>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center font-inter-medium">
                        <div class="line-right-1"></div>
                        <p class="fs-lg--1">{{ dataItem.district }}</p>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center font-inter-medium">
                        <div class="line-right-1"></div>
                        <p class="fs-lg--1">{{ dataItem.bid_submission_last_date }}</p>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center font-inter-medium">
                        <div class="line-right-1"></div>
                        <p class="fs-lg--1">{{ dataItem.auction_start_date }}</p>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center font-inter-medium">
                        <div class="line-right-1"></div>
                        <p class="fs-lg--1">{{ dataItem.auction_end_date }}</p>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center font-inter-medium">
                        <div class="line-right-1"></div>
                        <p class="fs-lg--1">
                            <span v-if="dataItem.reverse_price">
                                ₹ {{ dataItem.reverse_price }}
                            </span>
                            <span v-else>₹ 0</span>
                        </p>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center font-inter-medium">
                        <div class="line-right-1"></div>
                        <p class="fs-lg--1">
                            <span v-if="dataItem.reverse_price">
                                ₹ {{ dataItem.reverse_price }}
                            </span>
                            <span v-else>₹ 0</span>
                        </p>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center font-inter-medium">
                        <div class="line-right-1"></div>
                        <p class="fs-lg--1">{{ dataItem.event_type }}</p>
                    </div>
                </div>

                <div class="col">
                    <div class="text-center font-inter-medium">
                        <!--                        <div class="line-right-1"></div>-->
                        <p class="fs-lg--1" v-if="dataItem.is_third_party">Other</p>
                        <p class="fs-lg--1" v-else>Bank e Auctions India.com</p>
                    </div>
                </div>

            </div>

            <div class="row d-sm-none d-md-none">

                <div class="col-12 mt-4">

                    <div class="fl-te-c">

                        <div class="fl-x fl-a-c">
                        <span class="logo-container-1 mr-2"><img class="img-fluid"
                                                                 src="../../assets/mobile/home/property-type.png"
                                                                 alt=""></span>
                            <span class="font-inter-semi-bold">Category</span>
                            <span class="ml-1 fl-x fl-a-c">
                            <img src="../../assets/mobile/home/icons/chevron-double-gray-16.png" class="img-filter"
                                 alt="">
                        </span>
                            <span class="ml-2 font-inter-bold pos-r" v-if="dataItem.property_category">
                            <span class="line-right-sm"></span>
                            {{ dataItem.property_category }}
                        </span> &nbsp;
                            <span class="address-text font-inter-medium ml-3">
                            {{
                                    dataItem.item_name
                                }}, {{ dataItem.state }}, {{ dataItem.district }}, {{ dataItem.pincode }}
                        </span>
                        </div>
                        <div class="btn-group">

                            <btn key="not-int" text="I'm Interested" @click="iAmInterestedClicked"
                                 icon="fa fa-heart-o" design="basic-b" size="md"
                                 color="secondary" class="border-r--2 font-inter-medium"/>

                            <router-link :to="'/auction/' + dataItem.auction_id + '/details/'">
                                <btn text="View" design="basic-a" size="md" color="secondary" class="px-5 border-r--2"/>
                            </router-link>
                        </div>

                    </div>

                </div>
            </div>

            <!--Responsive-->
            <div class="d-lg-none">
                <div class="fl-x fl-a-c">
                    <div class="fl-gr-1">
                        <div class="fl-x fl-j-sa fl-a-c">

                        <span class="logo-container">
                            <img class="img-fluid" :src="dataItem.event_bank.bank_logo" alt="">
                        </span>

                            <span class="pr-4">{{ dataItem.auction_id }}</span>
                        </div>
                    </div>

                    <div class="fl-gr-1 pl-2 pl-lg-0"><span>{{ dataItem.event_bank.bank_name }}</span></div>

                    <div class="fl-gr-1 pl-2 pl-lg-0"><span>₹ {{ dataItem.reverse_price }}</span></div>
                </div>

                <div class="row b-1 fs--1">
                    <span class="col-12">
                    <div class="p-1">
                    Bid Submission Last Date :<span class="ml-2 font-weight-light">
                        {{ dataItem.bid_submission_last_date }}
                    </span>
                    </div>
                </span>

                    <span class="col-12 b-1">
                    <div class="p-1">
                        <span class="font-inter-medium">Auction Start Date :</span>
                        <span class="ml-2 font-weight-light">{{ dataItem.auction_start_date }}</span>
                    </div>
                </span>
                    <span class="col-12">
                    <div class="p-1">
                    Auction End Date :<span class="ml-2 font-weight-light">{{ dataItem.auction_end_date }}</span>
                    </div>
                </span>

                    <span class="b-1 w-100 col-6">
                    <div class=" fl-x fl-a-c h-100">
                        <span class="">City : {{ dataItem.district }}</span>
                    </div>
                </span>

                    <span class="b-1 w-100 col-6">
                    <div class="p-1">
                        EMD : <span class="ml-2">₹ {{ dataItem.emd_amount }}</span>
                    </div>
                </span>

                    <span class="b-1 col-6">
                    <div class="p-1">
                         Event Type : <span class="ml-2">
                    {{ dataItem.event_type }}</span>
                    </div>
                </span>

                    <!--                <span class="b-1 col-6">-->
                    <!--                    <div class="fs&#45;&#45;2 p-1">-->
                    <!--                         Other Auction :-->
                    <!--                        <span v-if="dataItem.is_third_party" class="ml-2">Yes</span>-->
                    <!--                        <span v-else class="ml-2">No</span>-->
                    <!--                    </div>-->
                    <!--                </span>-->
                    <span class="b-1 col-6">
                    <div class="p-1">
                        <span class="m-0 mb-1">Platform : </span>
                        <span v-if="dataItem.is_third_party" class="ml-2">Other</span>
                        <span v-else class="">Bank e Auctions India.com</span>
                    </div>
                </span>
                    <span class="b-1 col-12">
                    <div class="text-center py-2">
                        <btn key="not-int" text="I'm Interested" @click="iAmInterestedClicked"
                             icon="fa fa-heart-o" design="basic-b" size="md"
                             color="secondary" class="border-r--2 font-inter-medium mr-2"/>
                        <router-link :to="'/auction/' + dataItem.auction_id + '/details/'">
                            <btn text="View" design="basic-a" size="md" color="secondary" class="px-5 border-r--2"/>
                        </router-link>
                    </div>
                </span>

                </div>

                <div class="mt-2">
                <span class="logo-container-1 mr-2">
                    <img class="icon-sm-1" src="../../assets/mobile/home/property-type.png" alt=""></span>
                    <span class="font-inter-medium">Category</span>

                    <span class="ml-2 fl-x fl-a-c d-inline-block">
                    <img src="../../assets/mobile/home/icons/chevron-double-gray-16.png"
                         class="img-filter icon-xs-1" alt="">
                </span>

                    <span class="ml-2 mr-2">{{ dataItem.property_category }}</span>|

                    <span class="">
                    {{ dataItem.item_name }}, {{ dataItem.state }}, {{ dataItem.district }}, {{ dataItem.pincode }}
                </span>
                </div>
            </div>
            <modal title="Interested Auction" class="no-close bs-5" ref="interestedAuctionModal"
                   :no-close-on-backdrop="true" width="40r" header-color="secondary">
                <b-form class="row" ref="interestedAuctionForm" :save-url="interestAddUrl"
                        @failure="formFailed" @success="formSuccess"
                        v-slot="{model, loading}" :save-params="{auction: dataItem.id}"
                        :initial-data="{request_call_back : false}">
                    <div class="col-12 col-lg-12 col-md-6">
                        <validated-input v-model="model.name" :disabled="loading" :rules="{required:true}"
                                         label="Name"></validated-input>
                    </div>
                    <div class="col-12 col-lg-12 col-md-6">
                        <validated-input type="email" name="Email" v-model="model.email" :disabled="loading"
                                         :rules="{required:true}" label="E-Mail"></validated-input>
                    </div>
                    <div class="col-12 col-lg-12 col-md-6">
                        <validated-input type="number" v-model="model.phone" :disabled="loading"
                                         :rules="{required:true, min:10, max:10}"
                                         label="Phone"></validated-input>
                    </div>
                    <div class="col-12 col-lg-12 col-md-6">
                        <validated-textarea v-model="model.query" :disabled="loading" :rules="{required:true}"
                                            label="Query"></validated-textarea>
                    </div>
                    <div class="col-12 col-lg-12 col-md-6">
                        <validated-checkbox v-model="model.request_call_back" :disabled="loading"
                                            label="I Agree to be Contacted..."></validated-checkbox>
                    </div>
                    <div class="col-12 col-lg-12">
                        <div class="btn-group-resp md-w-50p w-100p">
                            <btn block :disabled="loading || !model.request_call_back" color="secondary" :loading="loading"
                             loading-text="Submitting"
                             text="Submit"></btn>
                        <btn block :disabled="loading" type="button" @click="closeModal()"
                             color="secondary" class="ml-md-2 mt-2 mt-md-0" text="Cancel"/>
                        </div>
                    </div>
                </b-form>
            </modal>
        </div>
    </div>
</template>

<script>
import urls from '../../data/urls';

export default {
    name: 'AuctionDetailsCard',

    props: {
        dataItem: {
            type: Object
        }
    },

    data () {
        return {
            interestAddUrl: urls.website.interestAddUrl
        };
    },

    methods: {
        iAmInterestedClicked () {
            this.$refs.interestedAuctionModal.show();
        },
        formSuccess (response) {
            this.$notify('Submitted Successfully!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$refs.interestedAuctionModal.close();
            this.$emit('reloadList');
        },
        formFailed (response) {
            if (response.data && response.data.message) {
                this.$notify(response.data.message, 'Failed',
                    {
                        type: 'danger'
                    }
                );
                const msg = 'Unable to add the auction to the list of interested auctions since the auction has already commenced.';
                if (response.data.message === msg) {
                    this.$refs.interestedAuctionModal.close();
                    this.$emit('reloadList');
                }
            } else {
                this.$notify('Enquiry Failed', 'Failed',
                    {
                        type: 'danger'
                    }
                );
            }
        },
        closeModal () {
            this.$refs.interestedAuctionModal.close();
            this.$emit('reloadList');
        }
    }
};
</script>

<style scoped lang="scss">
.logo-container {
    width: 2.5rem;
    height: auto;
    @media (min-width: 992px) {
        width: 3.5rem;
    }
}

.logo-container-1 {
    width: 1.5rem;

    img {
        max-height: 2.5rem;
    }

    //height: auto;
    @media (min-width: 992px) {
        width: 2rem;
        img {
            max-height: unset;
        }
    }
}

.c-card {
    span {
        //font-weight: 550;
        font-family: inter-medium;
    }

    .logo-container {
        width: 2.5rem;
        height: auto;
        @media (min-width: 992px) {
            width: 3.5rem;
        }
    }
}

.address-text {
    font-family: 'inter-regular' !important;
}

.img-filter {
    filter: grayscale(100%);
}

.header-section-min-height {
    @media (min-width: 992px) {
        min-height: 5.5rem;
    }
}
</style>
