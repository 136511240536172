<template>
    <div class="of-h">
        <div class="ml-5">
            <div class="container">
                <auction-filters class="mb-5" @filterUpdate="updateTable"></auction-filters>

            </div>
        </div>

        <div class="container fl-eq" v-if="loading">
            <loading-animation/>
        </div>

        <div v-else class="auction-section-bg py-4">
            <div class="container">
                <div class="row mx-lg-n6">
                    <div class="col-12">
                        <div class="fl-x mb-3 fs--2 font-inter-medium of-h">
                            <div class="order-1 order-lg-1 w-14re-res">
                                <div class="pos-r">
                                    <span class="line-right-r--1-5"></span>
                                    <div class="text-right">Auction Id</div>
                                </div>
                            </div>
                            <div class="pl-lg-4 order-2 order-lg-2 w-12re-res" style=""
                                 @click="sortBankName === 'bank_name|asc' ? sortBankName = 'bank_name|desc' : sortBankName ='bank_name|asc'">

                                <div class="text-center w-100 fl-x fl-a-s cursor-pointer pos-r">
                                    <span class="line-right-r--1-5"></span>
                                    <div class="ml-3 ml-lg-3">Bank/FI Name</div>
                                    <div class="fl-y mt-lg-1">
                                        <img class="rotate-90 icon-xs-2"
                                             src="../../assets/mobile/home/icons/chevrons-opposite-24.png"
                                             alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="order-3 order-lg-5 w-11-re-res" style="">
                                <div class="w-100 fl-x fl-j-s fl-a-s cursor-pointer pos-r"
                                     @click="sortReversedPrise === 'reverse_price|asc' ? sortReversedPrise = 'reverse_price|desc' : sortReversedPrise ='reverse_price|asc'">

                                    <span class="line-right-r-0"></span>
                                    <div class="ml-3" align="center">Reserve Price</div>
                                    <div class="fl-y mt-lg-1">
                                        <img class="rotate-90 icon-xs-2"
                                             src="../../assets/mobile/home/icons/chevrons-opposite-24.png"
                                             alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="order-4 order-lg-3 d-sm-none d-md-none p-0" style="width: 12.2% !important;"
                                 @click="sortDistrict === 'district|asc' ? sortDistrict = 'district|desc' : sortDistrict ='district|asc'">

                                <div class="text-center w-100 fl-x fl-j-c fl-a-s cursor-pointer p-0 pos-r">
                                    <span class="line-right"></span>
                                    <div class="ml-4 mr-5">City</div>
                                    <div class="fl-y mt-lg-1">
                                        <img class="rotate-90 icon-xs-2"
                                             src="../../assets/mobile/home/icons/chevrons-opposite-24.png"
                                             alt="">
                                    </div>
                                </div>
                            </div>

                            <div class="order-5 order-lg-4 d-sm-none d-md-none" style="width: 11.8% !important;"
                                 @click="sortBidSubmissionLastDate === 'bid_submission_last_date|asc' ?
                                 sortBidSubmissionLastDate = 'bid_submission_last_date|desc' :
                                 sortBidSubmissionLastDate = 'bid_submission_last_date|asc'">

                                <div class="text-center w-100 fl-x fl-j-c fl-a-s cursor-pointer pos-r">
                                    <span class="line-right"></span>
                                    <div class="mr-0 ml-4">Bid Submission Last Date</div>
                                    <div class="fl-y mt-lg-1">
                                        <img class="rotate-90 icon-xs-2"
                                             src="../../assets/mobile/home/icons/chevrons-opposite-24.png"
                                             alt="">
                                    </div>
                                </div>
                            </div>

                            <div class="order-6 order-lg-4 d-sm-none d-md-none" style="width: 11.8% !important;"
                                 @click="sortStartDate === 'auction_start_date|asc' ? sortStartDate = 'auction_start_date|desc' : sortStartDate = 'auction_start_date|asc'">

                                <div class="text-center w-100 fl-x fl-j-c fl-a-s cursor-pointer pos-r">
                                    <span class="line-right"></span>
                                    <div class="mr-0 ml-4">Auction <br> Start Date</div>
                                    <div class="fl-y mt-lg-1">
                                        <img class="rotate-90 icon-xs-2"
                                             src="../../assets/mobile/home/icons/chevrons-opposite-24.png"
                                             alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="order-7 order-lg-4 d-sm-none d-md-none" style="width: 11.6% !important;"
                                 @click="sortLastDate === 'auction_end_date|asc' ? sortLastDate = 'auction_end_date|desc' : sortLastDate = 'auction_end_date|asc'">

                                <div class="text-center w-100 fl-x fl-j-c fl-a-s cursor-pointer plr-0 pos-r">
                                    <span class="line-right-r-0"></span>
                                    <div class="mr-0 ml-4">Auction <br/> End Date</div>
                                    <div class="fl-y mt-lg-1">
                                        <img class="rotate-90 icon-xs-2"
                                             src="../../assets/mobile/home/icons/chevrons-opposite-24.png"
                                             alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="order-8 order-lg-5 d-sm-none d-md-none" style="width: 11.5% !important;"
                                 @click="sortEMD === 'emd_amount|asc' ? sortEMD = 'emd_amount|desc' : sortEMD ='emd_amount|asc'">

                                <div class="text-center w-100 fl-x fl-j-c fl-a-s cursor-pointer pos-r">
                                    <span class="line-right-r-0"></span>
                                    <div class="mr-0">EMD</div>
                                    <div class="fl-y mt-lg-1">
                                        <img class="rotate-90 icon-xs-2"
                                             src="../../assets/mobile/home/icons/chevrons-opposite-24.png"
                                             alt="">

                                    </div>
                                </div>
                            </div>
                            <div class="order-9 order-lg-6 d-sm-none d-md-none" style="width: 11% !important;">
                                <div class="text-center w-100 pos-r">
                                    <span class="line-right-r-0"></span>
                                    Event Type
                                </div>
                            </div>
                            <div class="order-10 order-lg-6 d-sm-none d-md-none" style="width: 10.5% !important;">
                                <div class="text-center w-100">
                                    <!--                                    <span class="line-right"></span>-->
                                    Platform
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row mx-lg-n6" v-if="auctions.data.length > 0">
                    <auction-details-card class="col-12" :class="{'mt-4': index > 0}" @reloadList="loadDetails"
                                          v-for="(item, index) in auctions.data" :key="index"
                                          :data-item="item"/>
                </div>

                <div class="row fl-eqh-c p-5" v-else>
                    No Data
                </div>

                <div class="row mt-3">

                    <div class="col-6 col-lg-3 order-1 order-lg-1">
                        Showing <span>{{ auctions.from }}</span> to <span>{{ auctions.to }}</span> of
                        {{ auctions.total }} entries
                    </div>

                    <auction-section-pagination
                        class="col-12 col-lg-6 order-3 order-lg-2" :last-page="lastPage"
                        :current-page="currentPage" @previousPage="loadDetails"
                        @nextPage="loadDetails" @gotoPage="loadDetails"/>

                    <div class="col-6 col-lg-3 order-2 order-lg-3">
                        <div class="fl-x fl-j-e">
                            <validated-select
                                class="w-50 c-pagination-count-dropdown c-input-text-c c-input-select-b pagination-select"
                                border-radius="3" placeholder="5/Page" v-model="perPage" :options="pageOptions"/>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import AuctionFilters from '../../components/auction/AuctionFilters';
import AuctionDetailsCard from '../../components/auction/AuctionDetailsCard';
import AuctionSectionPagination from '../../components/auction/AuctionSectionPagination';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'AuctionItems',

    components: { AuctionSectionPagination, AuctionDetailsCard, AuctionFilters },

    data () {
        return {
            loading: true,
            error: false,

            listUrl: urls.auction.upcoming,

            pageOptions: [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 15, value: 15 },
                { label: 20, value: 20 }
            ],

            perPage: 5,
            currentPage: 1,
            lastPage: '',

            auctions: {},

            params: {},

            sortDistrict: 'district|asc',
            sortEMD: 'emd_amount|asc',
            sortLastDate: 'auction_end_date|asc',
            sortBidSubmissionLastDate: 'bid_submission_last_date|asc',
            sortStartDate: 'auction_start_date|asc',
            sortReversedPrise: 'reverse_price|asc',
            sortBankName: 'bank_name|asc'
        };
    },

    mounted () {
        this.loadDetails();
    },

    watch: {
        perPage () {
            this.loadDetails();
        },

        sortDistrict () {
            this.setSort(this.sortDistrict);
        },

        sortEMD () {
            this.setSort(this.sortEMD);
        },

        sortBidSubmissionLastDate () {
            this.setSort(this.sortBidSubmissionLastDate);
        },

        sortLastDate () {
            this.setSort(this.sortLastDate);
        },

        sortReversedPrise () {
            this.setSort(this.sortReversedPrise);
        },

        sortBankName () {
            this.setSort(this.sortBankName);
        },

        name () {
            this.loadDetails();
        }
    },

    computed: {
        name () {
            return this.$route.params.name;
        }
    },

    methods: {
        setParams (currentPage = this.currentPage, model = null, sortKey = null) {
            let params = {
                page: currentPage,
                per_page: this.perPage,
                search: this.search,
                base_type: this.name,
                app: 'website'
            };

            if (sortKey) {
                params = {
                    ...params,
                    sort: sortKey
                };
            }

            if (model) {
                return {
                    ...params,
                    ...model
                };
            } else {
                return params;
            }
        },

        setSort (key) {
            this.loadDetails(this.currentPage, null, key);
        },

        loadDetails (currentPage = this.currentPage, model = null, sortKey = null) {
            console.log('model', model);
            const that = this;
            that.loading = true;
            that.error = false;

            const params = this.setParams(currentPage, model, sortKey);

            axios.get(that.listUrl, {
                params: params
            }).then(function (response) {
                that.auctions = { ...response.data };
                that.currentPage = that.auctions.current_page;
                that.lastPage = that.auctions.last_page;
                that.loading = false;
            }).catch(function (exception) {
                that.loading = false;
                that.error = true;
                console.log('exception occurred during the table loading : ', exception);
            });
        },

        updateTable (model) {
            console.log('update model', model);
            this.loadDetails(this.currentPage, model);
        }
    }
};
</script>

<style scoped lang="scss">
.auction-section-bg {
    background: rgb(240, 240, 240);
    background: linear-gradient(180deg, rgba(240, 240, 240, 1) 0%, rgba(255, 255, 255, 1) 35%);
}

.w-14re-res {
    width: 25.5% !important;
    @media (min-width: 992px) {
        width: 16.5% !important;
    }
}

.w-12re-res {
    width: 35.5%;
    @media (min-width: 992px) {
        /*width: 12.9% !important;*/
        width: 13.1% !important;
    }
}

.w-11-re-res {
    width: 40.8%;
    @media (min-width: 992px) {
        width: 11.8% !important;
    }
}
</style>
